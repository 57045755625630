import { uploadFile as uploadImage } from '../../../db/upload/file'
import getFileSrc from '../../../utils/getFileSrc'

const uploadCallback = file => {
  const formData = new FormData()

  formData.append('uploads[]', file, file.name)

  return uploadImage(formData)
    .then(fileData => {
      return {
        data: {
          link: getFileSrc(fileData._id),
        },
      }
    })
}

export default {
  options: [
    'blockType',
    'inline',
    'textAlign',
    'list',
    'link',
    'image',
    'history',
  ],
  image: {
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: uploadCallback,
    previewImage: true,
    inputAccept: 'image/jpeg,image/jpg,image/png',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: '100%',
    },
  },
}