import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import BackgroundFieldWrapper from '../BackgroundFieldWrapper'


const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: 4,
  },
})

const Input = (props) => {
  const {
    name,
    label,
    labelShrink,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    /* eslint-disable react/prop-types*/
    /* eslint-disable no-unused-vars*/
    handleSubmit,
    isSubmitting,
    isValidating,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    validateField,
    setError,
    setErrors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    setFormikState,
    isValid,
    initialValues,
    registerField,
    unregisterField,
    handleReset,
    validateOnChange,
    validateOnBlur,
    dirty,
    /* eslint-enable react/prop-types*/
    /* eslint-enable no-unused-vars*/
    ...custom
  } = props

  return (
    <BackgroundFieldWrapper style={{
      marginTop: 0,
      display: 'flex',
    }
    }>
      <BackgroundFieldWrapper
        style={{
          backgroundColor: '#fff',
          margin: 0,

        }}
      >
        <TextField
          className={`TextField-${name}`}
          name={name}
          label={label || name}
          value={values[name]}
          helperText={(touched[name] && errors[name]) ? errors[name] : ''}
          onChange={handleChange}
          multiline
          onBlur={handleBlur}
          error={(touched[name] && errors[name]) ? true : false}
          InputLabelProps={{
            shrink: labelShrink,
          }}
          {...custom}
        />
      </BackgroundFieldWrapper>
    </BackgroundFieldWrapper>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.object,
  handleBlur: PropTypes.func,
  labelShrink: PropTypes.bool,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}


export default withStyles(styles)(Input)