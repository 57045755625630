import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import Input from './Input'
import Preview from './Preview'
import FullScreenButton from './FullScreenButton'

import { saveTmpContent } from '../../../db/rest/mdxPreview'

const MDXEditor = (props) => {

  const [tmpPreviewId, setTmpPreviewId] = useState('')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [isError, setIsError] = useState(false)

  const value = props.values[props.name]

  const mainContainerStyle = (
    isFullScreen ?
      {
        position: 'fixed',
        zIndex: 99999,
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        backgroundColor: '#fff',
        padding: 16,
        boxSizing: 'border-box',
      }
      :
      {}
  )

  useEffect(
    () => {
      if (value === undefined) return

      setIsFetching(true)
      setIsError(false)

      saveTmpContent(value)
        .then(data => setTmpPreviewId(data._id))
        .catch(() => setIsError(true))
        .finally(() => setIsFetching(false))
    },
    [value]
  )

  return (
    <div style={mainContainerStyle}>
      <Grid container spacing={16}>
        <Grid xs={12} item container>
          <FullScreenButton
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
          />
        </Grid>
        <Grid xs={12} sm={6} item container>
          <Input fullWidth={true} {...props} />
        </Grid>
        <Grid xs={12} sm={6} item container>
          <Preview
            isFetching={isFetching}
            isError={isError}
            tmpPreviewId={tmpPreviewId}
          />
        </Grid>
      </Grid>
    </div>
  )

}

MDXEditor.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
}

export default MDXEditor