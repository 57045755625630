import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'

const FullScreenButton = ({ isFullScreen, setIsFullScreen }) => (
  <Button
    fullWidth={true}
    color={'default'}
    onClick={() => setIsFullScreen(!isFullScreen)}
    style={{backgroundColor: '#fafafa'}}
  >
    {
      isFullScreen ?
        'Wyłącz tryb full screen'
        :
        'Włącz tryb full screen'
    }
  </Button>
)

FullScreenButton.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  setIsFullScreen: PropTypes.func.isRequired,
}

export default FullScreenButton